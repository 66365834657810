angular.module('app')
	.directive('dateTimePicker', ['moment', function (moment) {
		var TWELVE_HOUR_DATE_TIME_STRING_TPL = _.template('<%= date %> <%= hour %>:<%= minute %> <%= meridiem %>');

		function link($scope, element, attrs, ngModelController) {

			setupChoices();
			setupTwoWayBinding();

			function setupTwoWayBinding() {
				$scope.$watch('day + hour + minute + meridiem', function () {
					if (_.isUndefined($scope.day)) {
                        ngModelController.$setViewValue(undefined);
					} else {
                        ngModelController.$setViewValue({
                            day: $scope.day,
                            hour: $scope.hour,
                            minute: $scope.minute,
                            meridiem: $scope.meridiem
                        });
                    }
				});

				ngModelController.$formatters.push(function (modelValue) {

					var day = modelValue.clone(),
						hours, minutes;

					day.minutes(Math.round(day.minutes() / 5) * 5); // round up to nearest 5 minute block;
					hours = day.hours();
					minutes = day.minutes();

					return {
						day: matchDateTimeToDayChoice(day),
						hour: '' + ((hours > 12) ? hours - 12 : (hours === 0) ? 12 : hours),
						minute: (minutes < 10) ? '0' + minutes : '' + minutes,
						meridiem: (hours >= 12) ? 'PM' : 'AM'
					};
				});

				ngModelController.$parsers.push(function (viewValue) {
					if (!viewValue || _.isEmpty(viewValue)) return undefined;

					var dateTimeAsString = TWELVE_HOUR_DATE_TIME_STRING_TPL({
							date: viewValue.day.format('YYYY-MM-DD'),
							hour: viewValue.hour,
							minute: viewValue.minute,
							meridiem: viewValue.meridiem
						}),
						modelValue = moment(dateTimeAsString, 'YYYY-MM-DD h:mm a');

					return modelValue;
				});

				ngModelController.$render = function () {
					$scope.day = ngModelController.$viewValue.day;
					$scope.hour = ngModelController.$viewValue.hour;
					$scope.minute = ngModelController.$viewValue.minute;
					$scope.meridiem = ngModelController.$viewValue.meridiem;
				};
			}

			function setupChoices() {
				var today = moment();

				$scope.dayChoices = [
					{	label: 'Now',
						value: undefined
					},
					{
						label: 'Today',
						value: today
					},
					{
						label: 'Tomorrow',
						value: today.clone().add(1, 'days')
					},
					{
						label: 'The day after',
						value: today.clone().add(2, 'days')
					}
				];
				$scope.hourChoices = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
				$scope.minuteChoices = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
				$scope.meridiemChoices = ['AM', 'PM'];
			}

			function matchDateTimeToDayChoice(dateTime) {
				var i, choice;

				for (i = 0; i < $scope.dayChoices.length; i++) {
					choice = $scope.dayChoices[i].value;
					if (dateTime.isSame(choice, "day")) {
						return choice;
					}
				}
				return choice[0];  // Default to first choice
			}
		}

		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {},
			link: link,
			templateUrl: '/static/templates/directive/date-time-picker.html'
		};
	}]);
